const style = {
  base: {
    color: '#101618',
    fontFamily: '"Poppins", sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#7A8390',
    },
  },
  invalid: {
    color: '#F26C67',
    iconColor: '#F26C67',
  },
};

export { style };
